
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";
import { LocalStorage } from "storage-manager-js";
import bus from "@/utils/bus";

export default defineComponent({
  name: "AsideMenu",
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    // 获取router
    const router = useRouter();
    // 路由跳转事件
    const goPage = (name: string): void => {
      router.push({ name });
    };

    const VAL = (path: string) => {
      let object = LocalStorage.get("COUNT-DATA") as any;
      let k = path.replace(/-/g, "_").replace("_manager", "_UNTREATED");
      return (
        object.countResultList?.find((item: any) => item.key == k)?.value || 0
      );
    };
    let version = ref(new Date());

    onMounted(() => {
      bus.on("on-count-data-change", () => {
        version.value = new Date();
      });
    });

    onUnmounted(() => {
      bus.off("on-count-data-change");
    });

    return {
      goPage,
      VAL,
      version,
    };
  },
});
