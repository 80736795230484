import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cce256ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"none"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_aside_menu = _resolveComponent("aside-menu", true)!
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.version), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (item.children && item.children.length > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (!item.meta.hide)
                ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                    index: item.name,
                    key: item.name
                  }, {
                    title: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(item.meta.icon)))
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("span", null, _toDisplayString(item.meta.name), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item_group, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_aside_menu, {
                            menuList: item.children
                          }, null, 8, ["menuList"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["index"]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (!item.meta.hide)
                ? (_openBlock(), _createBlock(_component_el_menu_item, {
                    key: item.name,
                    index: item.name,
                    onClick: ($event: any) => (_ctx.goPage(item.name))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(item.meta.icon)))
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_badge, {
                        hidden: _ctx.VAL(item.path) <= 0,
                        value: _ctx.VAL(item.path),
                        class: "item"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(item.meta.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["hidden", "value"])
                    ]),
                    _: 2
                  }, 1032, ["index", "onClick"]))
                : _createCommentVNode("", true)
            ], 64))
      ], 64))
    }), 256))
  ], 64))
}