
import { defineComponent, computed, defineAsyncComponent, ref } from "vue";
import { useStore } from "@/store/index";
import AsideMenu from "./includes/AsideMenu.vue";

export default defineComponent({
  name: "Aside",
  components: {
    AsideMenu,
    AsideHeader: defineAsyncComponent(
      () => import("./includes/AsideHeader.vue")
    )
  },
  setup() {
    // 获取state
    const { state } = useStore();
    // 展示状态
    const currentMenu = computed(() => state.authData.currentMenu);
    const menuRoutes = computed(() => state.authData.menuRoutes);

    const isCollapse = ref(false);

    return {
      isCollapse,
      currentMenu,
      menuRoutes
    };
  }
});
